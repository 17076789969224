<template>
  <v-layout>
    <v-dialog v-model="landingPageOptions.simulationDrawer" scrollable max-width="920px">
      <v-card>
        <v-layout o-hidden>
          <v-layout avatar column>
            <v-flex px-4 py-2 simulation-father>
              <button class="close-modal" @click="landingPageOptions.simulationDrawer = false">
                <i class="fal fa-times"></i>
              </button>
              <v-flex shrink simulation-header>
                <h3 class="color-theme-texto">
                  <i class="fal fa-calculator-alt mr-3 color-theme-secundaria"></i>
                  Simule sua doação
                </h3>
                <p class="color-theme-texto">
                  Utilize o simulador para fazer o cálculo aproximado do seu potencial de doação anual que é referente
                  <strong>a {{ campaign ? campaign.simulatorPercentage : '' }}% do seu imposto de renda devido.</strong>
                </p>
                <p class="ma-0 color-theme-texto">
                  Para sua segurança, nenhuma informação digitada nesse formulário será armazenada, apenas o valor final.
                </p>
                <p>
                  <br />
                  <strong>Importante:</strong>
                  <br />
                  1 - Não esqueça de preencher todas as informações referentes às suas despesas dedutíveis (dependentes, instrução, saúde, previdência etc.). Essas informações são fundamentais para a acuracidade do cálculo.
                  <br />
                  2 - Caso tenha tido alguma alteração salarial no ano vigente, o cálculo do salário mensal deverá ser pela média dos salários dos 12 meses (janeiro a dezembro) do ano vigente.
                </p>
              </v-flex>
              <v-flex shrink form-simulation pb-0>
                <h4 class="color-theme-secundaria">Rendimentos Tributáveis</h4>
                <v-layout wrap row space-around>
                  <v-flex xs12 sm6 md3 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Salário Bruto Mensal"
                      v-model="simulator.monthlySalary"
                      :infoVisible="true"
                      :precision="2"
                      titleInfo="Salário Bruto Mensal"
                      subTitleInfo="Informe o valor do seu salário mensal bruto, antes dos descontos."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md4 pa-1 mr-2 ml-2>
                    <InputMoney
                      type="text"
                      textLabel="Rendimentos Mensais Aluguel"
                      v-model="simulator.monthlyProceeds"
                      ref="monthlyProceeds"
                      :precision="2"
                      :infoVisible="true"
                      titleInfo="Rendimentos Mensais Aluguel"
                      subTitleInfo="Rendimentos mensais aluguel - (i) Insira o valor de receitas recebidas provenientes de aluguéis de imóveis."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md3 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Bônus Anual (Exceto PLR)"
                      ref="annualBonus"
                      :precision="2"
                      v-model="simulator.annualBonus"
                      :infoVisible="true"
                      titleInfo="Bônus Anual (Exceto PLR)"
                      subTitleInfo="Informe o valor do bônus anual recebido no ano vigente. Não considerar PLR (participação em lucros e resultados), dividendos ou distribuição de lucros. Em caso de dúvida, cheque com seu RH."
                    />
                  </v-flex>
                </v-layout>
                <h4 class="color-theme-secundaria">Despesas Dedutíveis</h4>
                <v-layout wrap row>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputSelect
                      textLabel="Tem dependentes? Quantos?"
                      ref="childrenQuantity"
                      :items="children"
                      v-model="simulator.childrenQuantity"
                      :infoVisible="true"
                      titleInfo="Tem dependentes? Quantos?"
                      subTitleInfo="Quantos dependentes existem."
                      :autoFocusOnValidationFail="false"
                    ></InputSelect>
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Despesa Mensal com Instrução/Educação"
                      ref="monthlyExpense"
                      :precision="2"
                      v-model="simulator.monthlyEducationExpense"
                      :infoVisible="true"
                      titleInfo="Despesa Mensal com Instrução/Educação"
                      subTitleInfo="Informe o valor de sua despesa mensal com instrução/educação com você e seus dependentes."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Despesas Médicas mensais (não reembolsadas)"
                      ref="monthlyMedicalExpense"
                      :precision="2"
                      v-model="simulator.monthlyMedicalExpense"
                      :infoVisible="true"
                      titleInfo="Despesas Médicas mensais (não reembolsadas)"
                      subTitleInfo="Informe as despesas médicas mensais realizadas por você com você e seus dependentes que não foram reembolsadas pelo plano de saúde. Ex.: Valor mensal do convênio médico, residual de consultas e procedimentos (cirurgias, fisioterapia etc)."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Outras deduções mensais"
                      :precision="2"
                      ref="monthlyDeductions"
                      v-model="simulator.otherDeductions"
                      :infoVisible="true"
                      titleInfo="Outras deduções mensais"
                      subTitleInfo="Funpresp, FAPI e Parcela isenta de aposentadoria, reserva remunerada, reforma e pensão para declarante com 65 anos ou mais, caso não tenha sido deduzida dos rendimentos. Carne-Leão: Livro Caixa."
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6 pa-1>
                    <InputMoney
                      type="text"
                      textLabel="Previdência Privada PGBL (Mensal)"
                      :precision="2"
                      ref="privatePension"
                      v-model="simulator.privatePension"
                      :infoVisible="true"
                      titleInfo="Previdência Privada PGBL (Mensal)"
                      subTitleInfo="Informe o valor que você paga mensalmente de Previdência Privada - PGBL. O valor de dedução é limitado a 12% do valor total dos rendimentos tributáveis. Quem opta pela VGBL não tem deduções pela declaração completa."
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
            <v-flex class="box-simulation p-relative" :class="{active: ShowSimulation}">
              <v-flex class="header-blue-simulation">
                <v-btn
                  v-if="!recalculate"
                  flat
                  round
                  color="white"
                  class="btn-primary mx-0 px-2"
                  large
                  @click="simulate(); $gtag('send', 'event', 'Calcular', 'Simulação', 'Simulação'), showButton=true"
                >Calcular</v-btn>
                <v-btn
                  v-else
                  flat
                  round
                  color="white"
                  class="btn-primary mx-0 px-2"
                  large
                  @click="simulate(), showButton=true"
                >Recalcular</v-btn>
                <div v-if="showButton">
                  <div>
                    <span>Saldo potencial de doação</span>
                    <h4>{{ result | FormatMoney }}</h4>
                  </div>
                  <a href="javascript:;" @click="ShowSimulation = !ShowSimulation ">
                    <i class="fal fa-chevron-up"></i>
                  </a>
                </div>
              </v-flex>
              <v-flex content-simulation bg-alpha v-show="SimulationMessage && ShowSimulation">
                <h3 v-if="SimulationTitle != ''">
                  <strong>{{SimulationTitle}}</strong>
                </h3>
                <h3>{{ SimulationMessage }}</h3>
                <v-btn
                  v-show="this.linkToDonation != '' && this.linkToDonation != undefined && showDonationButton"
                  flat
                  round
                  color="white"
                  class="btn-primary mx-0 px-2"
                  large
                  @click="gotoDonation()"
                >Doar</v-btn>
              </v-flex>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card>
      <v-divider></v-divider>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import InputText from "@/components/input/InputText.vue";
import Simulator from "@/scripts/models/simulator.model.js";
import { CampaignPhases } from "@/scripts/models/enums/campaignPhases.enum";
import { mapGetters, mapActions } from "vuex";
import { calculateDonationPotential } from "@/scripts/helpers/simulation.helper";

export default {
  components: {
    InputText
  },
  props: {
    landingPageOptions: false,
    linkToDonation: "",
    campaign: Object
  },
  data() {
    return {
      dialog: true,
      simulator: new Simulator(),
      ShowSimulation: false,
      SimulationMessage: "",
      SimulationTitle: "",
      children: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
      result: 0,
      suggestedValue: 0,
      incomeTotal: 0,
      formatterHelper: null,
      testText: "",
      showButton: false,
      showDonationButton: true,
      recalculate: false,
      fontListDestaque: [
        "Alegreya",
        "Eb Garamond",
        "Roboto Slab",
        "Hepta Slab",
        "Zilla Slab",
        "Playfair Display",
        "Montserrat",
        "Raleway",
        "Oswald",
        "Ubuntu",
        "Dosis",
        "Red Hat Display"
      ]
    };
  },
  watch: {
    campaign(value) {
      if (value) {
        this.showDonationButton =
          value.campaignPhaseId == CampaignPhases.EmAndamento &&
          value.published;
        this.loadFromSessionStorage();
      }
    }
  },
  mounted(){
    if(this.campaign)
      this.loadFromSessionStorage();
  },
  computed: {
    ...mapGetters([
      'incomeTax',
    ]),
  },
  methods: {
    simulate() {
      const percentage = this.campaign.simulatorPercentage

      console.log(percentage)

      const income = {
        salarioBruto: this.simulator.monthlySalary,
        aluguel: this.simulator.monthlyProceeds,
        bonusAnual: this.simulator.annualBonus
      }

      const expenses = {
        dependentes: this.simulator.childrenQuantity,
        educacao: this.simulator.monthlyEducationExpense,
        despesasMedicas: this.simulator.monthlyMedicalExpense,
        outrasDeducoes: this.simulator.otherDeductions,
        previdenciaPrivada: this.simulator.privatePension
      }
      
      if (!this.validForm(income)) {
        this.setResult({ suggestedDonation: 0 }, "Você precisa inserir, pelo menos, os valores de seus rendimentos tributáveis.")
        return;
      }

      const result = calculateDonationPotential(
        percentage,
        income.salarioBruto,
        income.aluguel,
        income.bonusAnual,
        expenses.dependentes,
        expenses.educacao,
        expenses.despesasMedicas,
        expenses.outrasDeducoes,
        expenses.previdenciaPrivada
      );

      if (result.insent) {
        this.setResult(
          result,
          "Isento de declaração",
          `Você não poderá abater suas doações no imposto de renda.
           Porém, você pode doar mesmo assim e acompanhar os impactos que ajudou a gerar.`,          
          true
        )
      } else if (result.type === "completa" && result.suggestedDonation > 0) {
        this.setResult(
          result,
          null,
          "Parabéns, este é seu potencial de doação!",
          false
        )
      } else {//if (result.type === "simples") {
        this.setResult(
          result,
          "Declaração simples",
          `Você não poderá abater suas doações no imposto de renda.
           Porém, você pode doar mesmo assim e acompanhar os impactos que ajudou a gerar`,
           true
        )
      }      
      return parseInt(result.suggestedDonation);
    },
    setResult(result, title, message, recalculate) {
      this.SimulationTitle = title;
      this.SimulationMessage = message;
      this.result = result.suggestedDonation;
      this.ShowSimulation = true;
      this.recalculate = recalculate;
      this.showButton = true
      this.$emit("calculate", result.suggestedDonation);
      this.fillSessionStorage(result.suggestedDonation)
    },
    fillSessionStorage(result) {
      sessionStorage.setItem('simulationResult', JSON.stringify({...this.simulator, result}));
    },
    loadFromSessionStorage() {
      const data = sessionStorage.getItem('simulationResult')
      if(data) {
        this.simulator = JSON.parse(data)
        this.simulate()        
      }
    },
    validForm(entries) {
      const filled = Object.keys(entries).filter(x => entries[x])
      return filled.length > 0
    },
    gotoDonation() {
      this.landingPageOptions.simulationDrawer = !this.landingPageOptions
        .simulationDrawer;
      this.$emit("donate", this.result);

      // if (!isNumber(this.linkToDonation)) {
      //   this.$emit("scrollToElement");
      // }
      // else if(isNumber(this.linkToDonation) && this.hasdDonationProduct){
      //   this.$emit("goToCart");
      // } else {
      //   this.$router.push({
      //     name: Routes.web.CartLogged,
      //     query: { projectId: this.linkToDonation }
      //   });
      // }
    }
  }
};
</script>